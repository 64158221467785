@import '~@astrid/components/dist/scss/utils';

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px;
  padding: 24px;
  border-right: 2px solid rgba(190, 194, 199, 0.2);
  min-height: 100vh;
  background: var(--color-primary);
  width: 220px;
  color: var(--color-secondary);
}

.nav {
  display: flex;
  flex-direction: column;
}

.menu {
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin: 0;
  padding: 0;
  list-style: none;
}

.submenu {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 0;
  padding: 0;
  list-style: none;
  margin-left: 16px;
}

.group {
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 16px;
  color: var(--color-text-secondary);
}

.logo {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  color: var(--color-secondary);
  user-select: none;
  line-height: 13px;

  svg {
    width: 80px;
    margin-right: 6px;
  }
}
