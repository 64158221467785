.table {
  // Move padding to direct link child
  :global(.MuiTableCell-root) {
    &:has(> a) {
      padding: 0;
    }

    & > a {
      padding: 16px;
    }
  }
}

.toolbarContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
}

.toolbarFilters {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.searchField {
  margin-left: auto;
  white-space: nowrap;
}

.emptyRow {
  height: 120px;
}
