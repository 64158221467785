@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

:root {
  --font-family: 'Roboto', sans-serif;

  --color-brand: #cfe3f0;

  --color-primary: #000000;
  --color-secondary: #ffffff;
  --color-border: #eeeeee;
  --color-border-hover: #000000;
  --color-error: #f75555;

  --color-grey-3: #eeeeee;

  --color-text-primary: #000000;
  --color-text-secondary: #9e9e9e;

  --dashboard-header-height: 74px;
}
