.ImageField {
  overflow: hidden;
  width: 153px;
  height: 153px;
  max-width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  box-sizing: content-box;
  &.isHelperText {
    padding-bottom: 20px;
  }
}

.inputWrapper {
  width: 100%;
  height: 100%;
  background: var(--color-grey-3);
  border: 1px dashed var(--color-border);
  box-sizing: border-box;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  display: flex;

  .isError & {
    border-color: var(--color-error);
  }

  svg {
    width: 40px;
    height: 40px;
  }

  input {
    cursor: pointer;
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 10;
  }

  img {
    object-fit: contain;
    & + .labelWrapper {
      display: none;
    }
  }
}

.labelWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--color-text-secondary);
  font-size: 14px;
  margin: 0 auto;
  justify-content: center;
}

.helperText {
  color: var(--color-text-secondary);
  margin: 6px 14px 4px;
  font-size: 0.75rem;
  margin-top: 3px;
  text-align: left;
  font-weight: 400;
  line-height: 1.66;

  .isError & {
    color: var(--color-error);
  }
}

.inputContainer {
}
