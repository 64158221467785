.container {
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr 150px 1fr;
}

.stats {
  grid-column: 3;
}

.textContainer {
  width: 100%;
  max-width: 800px;
  height: 210px;
  min-height: 210px;
  overflow-y: auto;
  resize: vertical;
}
.inputContainer {
  :global(.MuiInputBase-root),
  :global(.MuiTextField-root),
  textarea {
    height: 100%;
  }
}

.buttonContainer {
  display: flex;
  justify-content: center;
  height: 210px;
}

.classificationContainer {
  padding: 8px;
}

.button {
  height: 40px;
  min-width: 120px;
  margin-left: 4px;
  margin-right: 4px;
  align-self: center;
}

.loadingContainer {
  @extend .textContainer;

  display: flex;
  justify-content: center;
  align-items: center;
}

.levelSourceText {
  margin-right: 4px;
  font-weight: bold;
}

.labelText {
  display: flex;
  flex-direction: row;
}

.otherWordSensesContainer {
  display: flex;
  flex-direction: column;
}

.otherWordContainer {
  justify-content: space-between;
  margin: 2px;
}
.popperTextContainer {
  display: flex;
  flex-direction: row;
}

.popperContainer {
  padding: 8px;
  display: flex;
  flex-direction: column;
}
