@import '~@astrid/components/dist/scss/utils';

.formButtons {
  display: flex;
  gap: 16px;
  margin-top: 16px;
}

.imageWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 6px;
  position: relative;
  margin: 16px 0;

  > span {
    position: absolute;
    background-color: white;
    padding: 0 6px;
    top: -12px;
    left: 8px;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    color: var(--color-text-secondary);
  }
}

.publishWarning {
  display: flex;
  color: var(--color-text-secondary);
  font-style: italic;
  margin: 16px 0;
}

.titleContainer {
  display: flex;
  gap: 16px;
  align-items: baseline;

  .titleField {
    flex: 1;
  }

  .label {
    margin-right: 0;
  }
}
