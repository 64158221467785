.listItemRow {
  display: flex;
  flex-direction: row;
}
.exerciseItemContent {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.listItemInnerContainer {
  display: flex;
  flex-direction: column;
}

.distractorsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.distractor {
  margin-left: 4px;
}

.singleWord {
  margin-left: 4px;
}

.singleOption {
  margin-left: 4px;
}

.missing {
  fill: red;
  opacity: 0.25;
}

.imageOptions {
  display: flex;
}
