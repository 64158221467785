.card {
  height: 100%;
}

.addForm {
  display: flex;
  align-items: flex-start;
  gap: 16px;
}

.rowItem {
  height: 40px;

  :global {
    .MuiInputBase-root {
      height: 100%;
    }
  }
}

.divider {
  margin: 32px 0;
}

.or {
  font-weight: bold;
  display: flex;
  align-items: center;
}

.submitButton {
  white-space: nowrap;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  list-style: none;
  margin: 0;
  padding: 0;
}

.contentCard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 16px;
  position: relative;
}

.contentType {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.8rem;
}

.bookType {
  display: flex;
  align-items: center;
  gap: 4px;
}

.dropLocation {
  border: 2px dashed var(--color-border);
  border-radius: 4px;
}

.deleteButton {
  position: absolute;
  right: 8px;
  top: 8px;
  display: none;

  .contentCard:hover & {
    display: block;
  }
}
