.formActions {
  margin: 20px -10px 0;
  display: flex;
  justify-content: flex-end;
}
.buttonsContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.buttonsGroup {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.imageWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 6px;
  position: relative;
  margin: 10px 0 20px 0;

  > span {
    position: absolute;
    background-color: white;
    padding: 0 6px;
    top: -12px;
    left: 8px;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    color: #777;
  }
}

.multiSelector {
  margin-top: 16px;
  margin-bottom: 8px;
}
