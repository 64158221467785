.table {
  // Move padding to direct link child
  :global(.MuiTableCell-root) {
    &:has(> a) {
      padding: 0;
    }

    & > a {
      padding: 16px;
    }
  }
}

.addButton {
  margin-left: 27px;
}

.searchField {
  margin-left: auto;
}

.emptyRow {
  height: 120px;
}
