.root {
  max-width: 1240px;
  margin: 0 auto;
  :global {
    .MuiCard-root {
      height: 100%;
    }
    .MuiCardContent-root {
      padding: 16px 26px;
    }
  }
}

.exerciseEdit {
  margin-left: auto;
  height: 50px;
  margin-top: 2px;
  margin-right: 40px;
}

.exerciseItemAvatar {
  width: 80px;
  height: 50px;
  margin-right: 8px;
}

.exerciseItemAvatarPaper {
  @extend .exerciseItemAvatar;
  font-size: 38px;
  font-weight: 400;
  text-align: center;
}

.listItemContainer {
  width: 100%;
}

.listItemRow {
  display: flex;
  flex-direction: row;
  margin-top: 8px;
}
.exerciseItemContent {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  flex-wrap: wrap;
  gap: 4px;
}

.listItemInnerContainer {
  display: flex;
  flex-direction: column;
}

.distractorsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.distractor {
  margin-left: 4px;
}

.singleOption {
  margin-left: 4px;
}
.dialogActions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.exerciseItemContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.promptContainer {
  height: 75px;
}

.arrowContainer {
  display: flex;
  flex-direction: column;
  width: 40px;
}
